interface RoomOption {
  id: number;
  name: string;
  value: string;
}

export default class Room {
  public id: number = 0;
  public buildingId: number = 0;
  public buildingName: string = "";
  public buildingPlanId?: number;
  public address: string = "";
  public age: string = "";
  public nearestStation: string = "";
  public name: string = "";
  public numberOfEmployees?: number;
  public monthlyRent?: number | string;
  public monthlyRentPerPerson?: number | string;
  public initialCost?: number | string;
  public floorSpace?: number | string;
  public floorSpacePerPerson?: number | string;
  public occupyType?: string;
  public maxCapacity?: number;
  public image?: string;
  public canShowRent: boolean = true;
  public isVacant?: boolean;
  public isVirtual: boolean = false;
  public disableEntry?: boolean;
  public isRecommendPlan?: boolean;
  public cvButtonLabel?: string;
  public options: RoomOption[] = [];

  constructor(obj: Partial<Room>) {
    Object.assign(this, obj);
  }
}
